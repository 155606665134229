import getPageProps from '@local/lib/api/getPageProps';
import { getPageBySlug } from '@local/lib/contentful/api/';
import { PAGE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import { amiiboPageLocaleAlternates } from '@local/lib/helpers/hreflang';
import { getGraph } from '@nintendo-of-america/graph-api';
import { queries } from '@local/lib/graph';
import { FAQ_DEEPLINK } from '@local/components/pages/Amiibo/amiiboConstants';
import * as C from '@local/components/pages/Amiibo';

export async function getServerSideProps(context) {
  const { res, locale } = context;
  const graph = getGraph(context);

  const page = await getPageBySlug({
    slug: `/amiibo/`,
    locale,
    pageContentType: PAGE_TYPES.MODULAR,
    graph,
  });
  if (!page) return { notFound: true };

  const { data } = await graph.query(queries.Amiibos, { locale, limit: 6 });
  if (!data?.amiibos.items.length) return { notFound: true };

  return getPageProps({
    page: { ...page, amiibos: data?.amiibos.items },
    analytics: {
      pageType: 'Merchandising',
      pageName: 'amiibo',
      pageCategory: 'amiibo',
    },
    res,
    localeAlternates: amiiboPageLocaleAlternates,
  });
}

export default function Amiibo({ page }) {
  const {
    content: { modules },
    amiibos,
  } = page;

  return (
    <>
      <C.AmiiboHeader
        logo={{
          assetPath: modules[0].modules[0].primary.assetPath,
          alt: modules[0].modules[0].alt,
        }}
        navItems={[
          modules[0].modules[1],
          modules[0].modules[2],
          {
            ...modules[0].modules[3],
            url: modules[0].modules[3].url + '#' + FAQ_DEEPLINK,
          },
        ]}
      />
      <C.AmiiboHero
        image={{
          assetPath: modules[1].modules[0].primary.assetPath,
          alt: modules[1].modules[0].alt,
          dimensions: modules[1].modules[0].primary.dimensions,
        }}
        heading={modules[1].modules[1].heading}
        body={modules[1].modules[1].body}
      />
      <C.AmiiboFeatured amiibos={amiibos} cta={modules[2].cta} />
      <C.AmiiboFeatures
        heading={modules[3].modules[0].heading}
        body={modules[3].modules[0].body}
        promos={[
          modules[3].modules[1],
          modules[3].modules[2],
          modules[3].modules[3],
        ]}
      />
      <C.AmiiboCallout
        heading={modules[4].modules[0].heading}
        body={modules[4].modules[0].body}
      />
      <C.AmiiboHowTo tiles={modules[5].modules} />
      <C.AmiiboFaq
        heading={modules[6].heading}
        faqs={modules[6].modules}
        deepLink={FAQ_DEEPLINK}
      />
    </>
  );
}
